import React, {createElement} from 'react';
import {createRoot} from 'react-dom/client';
import Application from 'javascripts/pwa/Application';

/**
 * Preload all images
 */
require.context('images', true);

/**
 * Preload all fonts
 */
require.context('fonts', true);

/**
 * Initialize Application
 */
const bodyNode = window.document.querySelector('body');
const mainNode = window.document.querySelector('main');

/**
 * Adds support of render callback
 *
 * @param component
 * @param callback
 * @returns {JSX.Element}
 */
function withCallback(component, callback = undefined) {
    return <div ref={callback}>{component}</div>;
}

const applicationProps = window.applicationStatus;

const applicationReactRoot = createRoot(mainNode);
const applicationReactElement = createElement(Application, applicationProps);
const applicationReactElementWithCallback = withCallback(applicationReactElement, () => {
    bodyNode.classList.remove('loading');
});

applicationReactRoot.render(applicationReactElementWithCallback);