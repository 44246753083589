import React, {useEffect, useState} from 'react';
import {get, parseRequestOptionsFromJSON} from '@github/webauthn-json/browser-ponyfill';

const fetchData = async (setState) => {
    try {
        const user = await (await fetch('/pwa_current_user')).json();

        setState([true, {user: user}]);
    } catch (error) {
        setState([false, error]);
    }
};

const signIn = async (setState, email, password) => {
    setState(undefined);

    try {
        const response = await (await fetch(
            '/pwa_sign_in',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            }
        )).json();

        if (response['status'] !== 'ok') {
            alert(`Ошибка: ${response['message']}`);
            fetchData(setState);
            return;
        }

        fetchData(setState);
    } catch (error) {
        setState([false, error]);
    }
};

const signOut = async (setState) => {
    setState(undefined);

    try {
        const response = await (await fetch(
            '/pwa_sign_out',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )).json();

        if (response['status'] !== 'ok') {
            alert(`Ошибка: ${response['message']}`);
            fetchData(setState);
            return;
        }

        fetchData(setState);
    } catch (error) {
        setState([false, error]);
    }
};

async function webAuthOptionsForAuthentication(email) {
    return fetch(
        '/pwa_web_auth_options_for_authentication',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email
            })
        });
}

async function webAuthVerifyAuthentication(email, publicKeyCredential) {
    return fetch(
        '/pwa_web_auth_verify_authentication',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                public_key_credential: publicKeyCredential
            }),
        });
}

async function webAuthSignIn(setState, email) {
    setState(undefined);

    try {
        const authenticationResponse = await (await webAuthOptionsForAuthentication(email)).json();

        if (authenticationResponse['status'] !== 'ok') {
            alert(`Ошибка: ${authenticationResponse['message']}`);
            fetchData(setState);
            return;
        }

        const options = parseRequestOptionsFromJSON({publicKey: authenticationResponse['payload']});
        const publicKeyCredential = await get(options);
        const verifyResponse = await (await webAuthVerifyAuthentication(email, publicKeyCredential)).json();

        if (verifyResponse['status'] !== 'ok') {
            alert(`Ошибка: ${verifyResponse['message']}`);
            fetchData(setState);
            return;
        }

        fetchData(setState);
    } catch (error) {
        setState([false, error]);
    }
}

/**
 * Auth
 *
 * @param {Object} props
 */
const Auth = ({}) => {
    const [state, setState] = useState(undefined);

    useEffect(() => {
        window.document.title = 'Auth | Kabbalah';
    }, []);

    useEffect(() => {
        fetchData(setState)
    }, []);

    if (state === undefined) {
        return (<div className="content-loader">Загрузка...</div>);
    } else if (state[0] === false) {
        return (<div className="content-error">Ошибка: {state[1].toString()}</div>);
    }

    const {user} = state[1];

    if (user['status'] === 'error') {
        const onSubmitPasswordAuth = (e) => {
            e.preventDefault();

            const form = e.target;
            const formData = new FormData(form);
            const data = Object.fromEntries(formData.entries());

            signIn(setState, data['password-auth-email'], data['password-auth-password'])
        };

        const onSubmitWebAuth = (e) => {
            e.preventDefault();

            const form = e.target;
            const formData = new FormData(form);
            const data = Object.fromEntries(formData.entries());

            webAuthSignIn(setState, data['web-auth-email'])
        };

        return (
            <div className="content-auth">
                <h2>Аутентификация</h2>
                <div className="content-password-auth">
                    <form onSubmit={onSubmitPasswordAuth}>
                        <input name="password-auth-email" placeholder="Электропочта"/>
                        <input type="password" placeholder="Пароль" name="password-auth-password"/>

                        <button>Войти по паролю</button>
                    </form>
                </div>
                <hr/>
                <div className="content-web-auth">
                    <form onSubmit={onSubmitWebAuth}>
                        <input name="web-auth-email" placeholder="Электропочта"/>

                        <button>Войти по FaceID</button>
                    </form>
                </div>
            </div>
        );
    }

    return (
        <div className="content-auth">
            <h2>Вы вошли как {user['payload']['name']}</h2>
            <div className="note">*привязать FaceID можно на странице настроек</div>

            <div className="qr">
                <h3>QR код для входа на мероприятия</h3>
                <img src={window.applicationQrCodePath} alt=""/>
            </div>

            <button onClick={() => signOut(setState)}>Разлогиниться</button>
        </div>
    );
};

Auth.displayName = 'Auth';

export default Auth;
