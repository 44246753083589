import React, {useEffect, useState} from 'react';
import {
    create,
    parseCreationOptionsFromJSON
} from "@github/webauthn-json/browser-ponyfill";

const fetchData = async (setState) => {
    try {
        const user = await (await fetch('/pwa_current_user')).json();

        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        const pushSubscription = await serviceWorkerRegistration.pushManager.getSubscription();

        let push = false;

        if (Notification.permission === 'granted' && pushSubscription) {
            push = true;
        }


        setState([true, {user: user, push: push}]);
    } catch (error) {
        setState([false, error]);
    }
};

async function webAuthOptionsForRegistration() {
    return fetch(
        '/pwa_web_auth_options_for_registration',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        });
}

async function webAuthVerifyRegistration(publicKeyCredential) {
    return fetch(
        '/pwa_web_auth_verify_registration',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                public_key_credential: publicKeyCredential
            }),
        });
}

async function tieWebAuth(setState) {
    setState(undefined);

    try {
        const registrationResponse = await (await webAuthOptionsForRegistration()).json();

        if (registrationResponse['status'] !== 'ok') {
            alert(`Ошибка: ${registrationResponse['message']}`);
            fetchData(setState);
            return;
        }

        const options = parseCreationOptionsFromJSON({publicKey: registrationResponse['payload']});
        const publicKeyCredential = await create(options);
        const verifyResponse = await (await webAuthVerifyRegistration(publicKeyCredential)).json();

        if (verifyResponse['status'] !== 'ok') {
            alert(`Ошибка: ${verifyResponse['message']}`);
            fetchData(setState);
            return;
        }

        fetchData(setState);
    } catch (error) {
        setState([false, error]);
    }
}

async function subscribeOnPushNotifications(setState) {
    setState(undefined);

    try {
        const permission = await Notification.requestPermission();

        if (permission !== 'granted') {
            alert('Вы не разрешили уведомления');
            fetchData(setState);
            return;
        }

        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        const pushSubscription = await serviceWorkerRegistration.pushManager.subscribe({
            applicationServerKey: window.applicationWebPushPublicKey,
            userVisibleOnly: true
        });

        const response = await fetch('/pwa_subscriptions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pushSubscription),
        });

        if (response.status !== 200) {
            alert('Не удалось сохранить вашу подписку');
            fetchData(setState);
            return;
        }

        fetchData(setState);
    } catch (error) {
        setState([false, error]);
    }
}

/**
 * Settings
 *
 * @param {Object} props
 */
const Settings = ({}) => {
    const [state, setState] = useState(undefined);

    useEffect(() => {
        window.document.title = 'Settings | Kabbalah';
    }, []);

    useEffect(() => {
        fetchData(setState)
    }, []);

    if (state === undefined) {
        return (<div className="content-loader">Загрузка...</div>);
    } else if (state[0] === false) {
        return (<div className="content-error">Ошибка: {state[1].toString()}</div>);
    }

    const {user, push} = state[1];

    if (user['status'] === 'error') {
        return (
            <div className="content-settings">
                <div className="error">Необходимо войти в систему</div>
            </div>
        );
    }

    return (
        <div className="content-settings">
            <h2>Настройки</h2>
            <div className="content-settings-push">
                <div>Push-уведомления: {push ? 'включено' : 'отключено'}</div>
                <button onClick={() => subscribeOnPushNotifications(setState)}>Подписаться на Push уведомления</button>
            </div>
            <div className="content-settings-web-auth">
                <button onClick={() => tieWebAuth(setState)}>Привязать FaceID</button>
            </div>
        </div>
    );
};

Settings.displayName = 'Settings';

export default Settings;
