import React, {useState} from 'react';
import Main from './Main';
import Events from './Events';
import Auth from './Auth';
import Settings from './Settings';

const isInWebAppiOS = (window.navigator.standalone === true);
const isInWebAppChrome = (window.matchMedia('(display-mode: standalone)').matches);

const sectionComponentFor = (name) => {
    switch (name) {
        case 'main':
            return (<Main/>);
        case 'events':
            return (<Events/>);
        case 'auth':
            return (<Auth/>);
        case 'settings':
            return (<Settings/>);
    }
};

/**
 * Application
 *
 * @param {Object} props
 */
const Application = (props) => {
    const [section, setSection] = useState('main');

    let content;

    if (props.serviceWorkerApi && props.notificationsApi && props.webAuthApi) {
        content = sectionComponentFor(section);
    } else {
        content = (
            <pre>
                System Error{'\n'}{'\n'}
                Service Worker API: {props.serviceWorkerApi ? 'ok' : 'error'}{'\n'}
                Notification API: {props.notificationsApi ? 'ok' : 'error'}{'\n'}
                Web Auth API: {props.webAuthApi ? 'ok' : 'error'}
            </pre>
        )
    }

    return (
        <>
            <div className="content">
                {content}
            </div>
            <div className="navigation">
                <div className={"navigation-item " + (section === 'main' ? 'selected' : '')} onClick={(_) => {
                    setSection('main')
                }}>🙂
                </div>
                <div className={"navigation-item " + (section === 'events' ? 'selected' : '')} onClick={(_) => {
                    setSection('events')
                }}>🎉
                </div>
                <div className={"navigation-item " + (section === 'auth' ? 'selected' : '')} onClick={(_) => {
                    setSection('auth')
                }}>🔑
                </div>
                <div className={"navigation-item " + (section === 'settings' ? 'selected' : '')} onClick={(_) => {
                    setSection('settings')
                }}>⚙️
                </div>
            </div>
        </>
    );
};

Application.displayName = 'Application';

export default Application;
