import React, {useEffect, useState} from 'react';

const fetchData = async (setState) => {
    try {
        const user = await (await fetch('/pwa_current_user')).json();
        const events = await (await fetch('/pwa_all_events')).json();

        setState([
            true,
            {
                user: user,
                events: events
            }
        ]);
    } catch (error) {
        setState([false, error]);
    }
};

/**
 * Events
 *
 * @param {Object} props
 */
const Events = ({}) => {
    const [state, setState] = useState(undefined);

    useEffect(() => {
        window.document.title = 'Events | Kabbalah';
    }, []);

    useEffect(() => {
        fetchData(setState)
    }, []);

    if (state === undefined) {
        return (<div className="content-loader">Загрузка...</div>);
    } else if (state[0] === false) {
        return (<div className="content-error">Ошибка: {state[1].toString()}</div>);
    }

    const {_user, events} = state[1];

    let eventsContent;
    if (events['status'] === 'ok') {
        eventsContent = (<div className="nothing">Нет ближайший событий :(</div>);

        if (events['payload'].length !== 0) {
            eventsContent = events['payload'].map((event) => <div>
                <div>{event['name']}</div>
                <div>{event['starts_at']}</div>
            </div>)
        }
    } else {
        eventsContent = `Ошибка при загрузке событий: ${events['message']}`;
    }

    return (
        <>
            <div className="content-events">
                <h2>События</h2>
                {eventsContent}
            </div>
        </>
    );
};

Events.displayName = 'Events';

export default Events;
